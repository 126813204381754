import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'

interface IProps {
    Modal: boolean
    HideModal: any
    OnChange: any
    Reporte: { FechaInicio: Date, FechaFin: Date, IdUsuario: number}
}

interface IState {
}

export default class ModalFecha extends React.Component<IProps, IState> {

    Locale = {
        firstDayOfWeek: 1,
        dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "octubre", "noviembre", "diciembre"],
        monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        today: "Hoy",
        clear: "Claro"
    }

    RenderFooter(){
        return (
            <div>
                <Button type="submit" label="Aceptar" icon="pi pi-check"/>
            </div>
        )
    }
    
    render(){
        return (
            <Dialog 
                header="Descarga del reporte de liquidaciones" 
                visible={this.props.Modal} 
                footer={this.RenderFooter()} 
                onHide={this.props.HideModal}>
                    <div className="p-grid nested-grid">
                        <div className="p-col-12 p-md-8 p-lg-6">
                            <div className="p-col">
                                <div className="p-grid p-fluid ">
                                    <div className="p-col-12">
                                        <label 
                                            className="p-d-block"
                                            style={{
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'}}
                                            >Fecha Inicio</label>
                                        <Calendar 
                                            value={new Date(this.props.Reporte.FechaInicio)} 
                                            onChange={this.props.OnChange} 
                                            name="FechaInicio"
                                            locale={this.Locale} 
                                            dateFormat="dd/mm/yy"
                                            inline/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-8 p-lg-6">
                            <div className="p-col">
                                <div className="p-grid p-fluid ">
                                    <div className="p-col-12">
                                        <label 
                                            className="p-d-block"
                                            style={{
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'}}
                                            >Fecha Fin</label>
                                        <Calendar 
                                            value={new Date(this.props.Reporte.FechaFin)} 
                                            onChange={this.props.OnChange} 
                                            name="FechaFin"
                                            locale={this.Locale} 
                                            dateFormat="dd/mm/yy" 
                                            inline />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Dialog>
        )
    }
}