import React from 'react'
import '../Assets/Css/Navigation.css'

interface IToken{
    sub: number
    Nombre: string
    Apellido: string
    IsDocumento: string
    IsAdministrador: string
    exp: number
    nbf: number
}

interface IProps {
    Token: IToken
}

interface IState {
    Time: string,
    TimeInterval: NodeJS.Timer | null
}


export default class NavigationBar extends React.Component<IProps, IState> {

    TimerHandle = null

    constructor(props : any){
        super(props)
        this.state = {
            Time : '',
            TimeInterval : null
        }
    }

    componentDidMount(){
        this.setState({TimeInterval : setInterval(
            () => this.CalcularHoraToken(), 1000
        )})
    }
    
    componentWillUnmount(){
        if(this.state.TimeInterval) clearInterval(this.state.TimeInterval)
    }

    CalcularHoraToken(){
        if(this.props.Token.sub > 0){
            var date : any = new Date(this.props.Token.exp * 1000)
            var hoy : any = new Date()
            
            var diff = Math.round((  date - hoy ) / 1000)
            if(diff < 0){
                localStorage.removeItem('TOKEN-UN1FL3X')
                window.location.href = "/"
            }else{
                var time = diff
                var hours = Math.floor( time / 3600 )
                var minutes : any = Math.floor( (time % 3600) / 60 )
                var seconds : any = time % 60
                minutes = minutes < 10 ? '0' + minutes : minutes
                seconds = seconds < 10 ? '0' + seconds : seconds
                var result = hours + ":" + minutes + ":" + seconds
                this.setState({ Time : result})
            }
        }
    }

    AsignarAdministrador(){
        if(this.props.Token.IsAdministrador === 'True'){
            return(
                <li><a href="/Administracion">Administración</a></li>
            )
        }
    }

    render(){
        return(
            <div className="NavigationBar">
                <header className="toolbar">
                    <nav className="toolbar_navigation">
                        <div>
                        </div>
                        <div className="toolbar_logo">
                            <a href="/Documento">Touchless Payments</a>
                        </div>
                        <div className="spacer"/>
                        <div className="toolbar_navigation_items">
                            <ul>
                                {this.AsignarAdministrador()}
                                <li style={{color : 'white'}}>{this.state.Time}</li>
                                <li><a href="/" onClick={() => localStorage.removeItem('TOKEN-UN1FL3X')}>Salir</a></li>
                            </ul>
                        </div>
                    </nav>
                </header>
            </div>
        )
    }
}