/* eslint-disable array-callback-return */
import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import ModalUsuario from '../Components/ModalUsuario'
import ModalAsignacionHoja from '../Components/ModalAsignacionHoja'
import { Usuario } from '../../Domain/Models/UsuarioDTO'
import { UsuarioService } from '../../Domain/Services/Usuario.service'
import '../Assets/Css/DataTable.css'

interface IErrores{
    Nombres: string, 
    Usuario: string, 
    Contraseña: string
}

interface IProps {
}

interface IState {
    Usuario: Usuario,
    Modal: {
        Mode: string,
        Open: boolean
    },
    ModalAsignacionHoja : boolean,
    Usuarios: Array<object>,
    HojasDocumento: Array<{PKID: string, Nombre: string}>,
    LEmpresa: Array<object>,
    Errores: IErrores
}

export default class Administracion extends React.Component<IProps, IState> {

    cErrores : IErrores = {
        Nombres: '',
        Usuario: '',
        Contraseña: ''
    }

    cUsuario : Usuario = {
        PKID : 0,
        Usuario : '',
        Contraseña : '',
        Users: {
            PKID : 0,
            Nombres : '',
            Apellidos : '',
            IsDocumento : false,
            IsAdministrador : false,
            UsuarioEmpresa : []
        },
        Empresa : {
            PKID : 0,
            Nombre : ''
        }
    }

    constructor(props : any){
        super(props)
        this.state = {
            Usuario: this.cUsuario,
            Modal: {
                Mode: '',
                Open: false
            },
            ModalAsignacionHoja: false,
            Usuarios: [],
            HojasDocumento: [],
            LEmpresa: [],
            Errores: this.cErrores
        }
    }

    componentDidMount(){
        this.ListarUsuarios()
        this.ListarHojasDocumento()
    }

    ListarUsuarios(){
        UsuarioService.Listar()
        .then((data : any) => {
            if(data.success) this.setState({Usuarios : JSON.parse(data.data)})
            else this.setState({Usuarios : []})
        })
        .catch((error) => {
            alert(error)
        })
    }

    ListarHojasDocumento(){
        UsuarioService.ListarHojasDocumento()
        .then((data : any) => {
            if(data.success) this.setState({HojasDocumento : JSON.parse(data.data)})
            else this.setState({HojasDocumento : []})
        })
        .catch((error) => {
            alert(error)
        })
    }

    RowAdministrador(DataRow : any){
        return (
            <div className="p-field-checkbox">
                <Checkbox 
                    inputId="binary" 
                    checked={DataRow.Users.IsAdministrador}
                    disabled/>
            </div>
        )
    }

    RowDocumento(DataRow : any){
        return (
            <div className="p-field-checkbox">
                <Checkbox 
                    inputId="binary" 
                    checked={DataRow.Users.IsDocumento}
                    disabled/>
            </div>
        )
    }

    HandleSelectRow(Usuario : any){
        this.setState({
            Usuario: Usuario,
            LEmpresa: Usuario.Users.UsuarioEmpresa
        },() => this.ShowModalUsuario('Edit'))
    }

    AsignarHoja = (Entidad : {PKID: string, Nombre: string}) =>{
        let band = true;
        if(this.state.LEmpresa){
            this.state.LEmpresa.map((empresa : any) =>{
                if(empresa.PKID === Entidad.PKID){
                    const Filter = this.state.LEmpresa.filter((item : any) => item.PKID !== empresa.PKID)
                    this.setState({LEmpresa : Filter})
                    band = false
                }
            })
        }
        if(band){
            const json : any = this.state.LEmpresa ? this.state.LEmpresa.concat(Entidad) : new Array(Entidad)
            this.setState({LEmpresa : json})
        }
    }

    HandleChange = (e : React.ChangeEvent<HTMLInputElement>) =>{
        const {name, value, checked} = e.target
        switch(name){
            case 'Nombres':{
                this.setState({
                    Usuario: {
                        ...this.state.Usuario,
                        Users:{
                            ...this.state.Usuario.Users,
                            Nombres : value,
                            Apellidos : value,
                        }
                    }
                })
                break}
            case 'IsDocumento': case 'IsAdministrador':
                this.setState({
                    Usuario: {
                        ...this.state.Usuario,
                        Users: {
                            ...this.state.Usuario.Users,
                            [name] : checked
                        }
                    }
                })
                break
            case 'Usuario': case 'Contraseña':
                this.setState({
                    Usuario: {
                        ...this.state.Usuario,
                        [name] : value
                    }
                })
                break
            default:
                break
        }
    }

    HideModalUsuario(){
        this.setState({
            Modal: {
                Mode: '',
                Open: false
            },
            Usuario: this.cUsuario,
            Errores: this.cErrores
        })
    }

    ShowModalUsuario(Mode : string){
        this.setState({
            Modal: {
                Mode: Mode,
                Open: true
            }
        })
    }

    HideModalAsignacionHoja(){
        this.setState({
            ModalAsignacionHoja: false,
            LEmpresa: this.state.Usuario.Users.UsuarioEmpresa
        })
    }

    ShowModalAsignacionHoja(){
        this.setState({
            ModalAsignacionHoja: true
        })
    }

    HandleSubmit = (e : any) =>{
        e.preventDefault()
        const result = UsuarioService.ValidarSchema(this.state.Usuario,this.state.Modal.Mode)
        if(result.Contraseña || result.Nombres || result.Usuario){
            return this.setState({Errores : result})
        }else{
            if(this.state.Modal.Mode === 'Create'){
                if(!UsuarioService.ExpresionRegular(this.state.Usuario.Contraseña)){
                    alert("No se acepta la contraseña, la contraseña debe contener: \n 1. Minimo de 8 caracteres y maximo de 15 caracteres. \n 2. Al menos una letra mayúscula, minuscula. \n 3. Al menos un digito numerico. \n 4. No se aceptan espacios en blanco. \n 5. Al menos un caracter especial (@, $, !, %, *, ?, &).")
                    return
                }
            }
            this.CrearActualizar()
        }
    }

    CrearActualizar(){
        UsuarioService.CrearActualizar(this.state.Usuario)
        .then((data : any) => {
            alert(data.data)
            if(data.success){}
                this.setState({
                    Modal: {
                        Mode: '',
                        Open: false
                    },
                    LEmpresa: [],
                    Errores: this.cErrores,
                    Usuario: this.cUsuario
                },() =>{
                    this.ListarUsuarios()
                    this.ListarHojasDocumento()
                })
        })
        .catch( (error) => {
            alert(error)
        })
    }

    HeaderDataTable(){
        return(
            <div className="p-d-flex p-jc-between">
                <Button 
                    label="Agregar usuario" 
                    onClick={(Event) => this.ShowModalUsuario('Create')} 
                    autoFocus/>
            </div>
        )
    }

    HandleSubmitHojaDocumento = (e : any) =>{
        e.preventDefault()
        this.setState({
            ModalAsignacionHoja : false,
            Usuario: {
                ...this.state.Usuario,
                Users: {
                    ...this.state.Usuario.Users,
                    UsuarioEmpresa: this.state.LEmpresa
                }
            }
        })
    }

    render(){
        return (
            <div className="DataTable">
                <DataTable 
                    value={this.state.Usuarios}
                    onSelectionChange={e => this.HandleSelectRow(e.value)} 
                    selectionMode="single"
                    header={this.HeaderDataTable()}
                    className="p-datatable-customers">
                    <Column field="Users.Nombres" header="Empresa" style={{ textAlign:'center' }}></Column>
                    <Column field="Usuario" header="Usuario" style={{ textAlign:'center' }}></Column>
                    <Column field="Users.IsDocumento" body={this.RowDocumento} header="Documento" style={{ textAlign:'center' }}></Column>
                    <Column field="Users.IsAdministrador" body={this.RowAdministrador} header="Administrador" style={{ textAlign:'center' }}></Column>
                </DataTable>
                <form onSubmit={this.HandleSubmit}>
                    <ModalUsuario
                        Modal = {this.state.Modal}
                        Usuario = {this.state.Usuario} 
                        HideModal = {() => this.HideModalUsuario()}
                        ShowModalAsignacionHoja = {() => this.ShowModalAsignacionHoja()}
                        HandleChange = {this.HandleChange}
                        Errores = {this.state.Errores}/>
                </form>
                <form onSubmit={this.HandleSubmitHojaDocumento}>
                    <ModalAsignacionHoja
                        Modal = {this.state.ModalAsignacionHoja}
                        HideModal = {() => this.HideModalAsignacionHoja()}
                        HojasDocumento = {this.state.HojasDocumento}
                        UsuarioEmpresa = {this.state.LEmpresa}
                        AsignarHoja = {this.AsignarHoja}/>
                </form>
            </div>
        )
    }
}