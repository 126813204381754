import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner'
import '../Assets/Css/Spinner.css'

interface IProps {
    Load: boolean
}

interface IState {
}

export default class Loading extends React.Component<IProps, IState> {
    render(){
        return(
            <>
                {this.props.Load ? 
                        <div className="PosSpinner">
                            <ProgressSpinner/>
                            <p>Consultando...</p>
                        </div>
                    :
                    <>
                    </>
                }
            </>
        )
    }
}