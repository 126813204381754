import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Checkbox} from 'primereact/checkbox'
import { Usuario } from '../../Domain/Models/UsuarioDTO'

interface IProps {
    Modal: {
        Mode: string,
        Open: boolean
    },
    Usuario: Usuario
    HideModal: any
    ShowModalAsignacionHoja: any
    HandleChange: any
    Errores: {Nombres: string, Usuario: string, Contraseña: string}
}

interface IState {
}

export default class ModalUsuario extends React.Component<IProps, IState> {

    RenderFooter(){
        return (
            <div className="p-d-flex p-jc-between">
                <Button type="button" label="Asignar documentos" onClick={(Event) => this.props.ShowModalAsignacionHoja(Event)}/>
                <Button type="submit" label="Aceptar" icon="pi pi-check" autoFocus />
            </div>
        )
    }

    render(){
        return (
            <Dialog 
                header="Información de usuario" 
                visible={this.props.Modal.Open} 
                style={{ width: '90vh' }} 
                footer={this.RenderFooter()} 
                onHide={this.props.HideModal}>
                <div className="p-grid nested-grid">
                    <div className={this.props.Modal.Mode === 'Create' ? "p-col-12 p-lg-4" : "p-col-12 p-lg-6"}>
                         <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Empresa</label>
                                    <InputText 
                                        name="Nombres"
                                        type="text"
                                        value={this.props.Usuario ? this.props.Usuario.Users.Nombres : ''}
                                        onChange={this.props.HandleChange}/>
                                    {this.props.Errores.Nombres && <small className="p-invalid p-d-block">{this.props.Errores.Nombres}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.props.Modal.Mode === 'Create' ? "p-col-12 p-lg-4" : "p-col-12 p-lg-6"}>
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Usuario</label>
                                    <InputText 
                                        name="Usuario"
                                        type="text"
                                        value={this.props.Usuario ? this.props.Usuario.Usuario : ''}
                                        onChange={this.props.HandleChange}/>
                                    {this.props.Errores.Usuario && <small className="p-invalid p-d-block">{this.props.Errores.Usuario}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.Modal.Mode === 'Create' ?
                            <div className="p-col-12 p-lg-4">
                                <div className="p-col">
                                    <div className="p-grid p-fluid ">
                                        <div className="p-col-12">
                                             <label 
                                                className="p-d-block"
                                                style={{
                                                    fontFamily: 'Sarala',
                                                    fontSize: '20px'}}
                                                >Contraseña</label>
                                            <InputText 
                                                name="Contraseña"
                                                type="password"
                                                value={this.props.Usuario ? this.props.Usuario.Contraseña : ''}
                                                onChange={this.props.HandleChange}/>
                                            {this.props.Errores.Contraseña && <small className="p-invalid p-d-block">{this.props.Errores.Contraseña}</small>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div></div>
                    }
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <div 
                                        className="p-field-checkbox" 
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'}}>
                                        <Checkbox 
                                            name="IsDocumento"
                                            inputId="binary" 
                                            onChange={this.props.HandleChange}
                                            checked={this.props.Usuario ? this.props.Usuario.Users.IsDocumento : false}/>
                                                <label 
                                                    className="p-d-block"
                                                    style={{
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'}}
                                            >IsDocumento</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <div 
                                        className="p-field-checkbox"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'}}>
                                        <Checkbox 
                                            name="IsAdministrador"
                                            inputId="binary" 
                                            onChange={this.props.HandleChange}
                                            checked={this.props.Usuario ? this.props.Usuario.Users.IsAdministrador : false}/>
                                            <label 
                                                className="p-d-block"
                                                style={{
                                                    fontFamily: 'Sarala',
                                                    fontSize: '20px'}}
                                        >IsAdministrador</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}