import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

import '../Assets/Css/ListaHojaDocumentos.css'

interface IProps {
    Modal: boolean
    HideModal: any
    HojasDocumento: Array<{PKID: string, Nombre: string}>
    UsuarioEmpresa: Array<object>
    AsignarHoja: (Entidad : {PKID: string, Nombre: string})=> void
}

interface IState {
}

export default class ModalAsignacionHoja extends React.Component<IProps, IState> {

    RenderFooter(){
        return (
            <Button 
                type="submit"
                label="Aceptar" 
                icon="pi pi-check"/>
        )
    }

    RenderPintado = (idEmpresa : string,UsuarioEmpresa : Array<object>) => {
        let className = 'content-list-li-sp'
        if(UsuarioEmpresa){
            UsuarioEmpresa.map((Entidad : any) => {
                if(Entidad.PKID === idEmpresa){
                    className = 'content-list-li-p'
                }
            })
        }
        return className;
    }

    RenderListado = (HojasDocumento : Array<{PKID: string, Nombre: string}>,UsuarioEmpresa : Array<object>) => {
        let lista = HojasDocumento.map((Entidad : {PKID: string, Nombre: string}) => {
            return (
                <li
                    id = {Entidad.PKID}
                    className = 'content-li'
                    key = {Entidad.PKID}
                    onClick={()=> this.props.AsignarHoja(Entidad)}>
                        <div className = {this.RenderPintado(Entidad.PKID,UsuarioEmpresa)}>
                            <div className="contenido">
                                {Entidad.Nombre}
                            </div>
                        </div>
                </li>
            )
        })
        return lista;
    }

    render(){
        return (
            <Dialog 
                header="Asignación de hojas" 
                visible={this.props.Modal} 
                style={{ width: '40vh' }} 
                footer={this.RenderFooter()} 
                onHide={this.props.HideModal}>
                    <div className="p-grid nested-grid">
                        <div className="p-col-12">
                            <div className="list">
                                <ul className="lista-contenedor">
                                    {this.RenderListado(this.props.HojasDocumento,this.props.UsuarioEmpresa)}
                                </ul>
                            </div>
                        </div>
                    </div>
                    
            </Dialog>
        )
    }
}