import { HTTP } from "../Http/Http"

export const UsuarioRepository = {
    Auntenticar: async (body : any) => {
        return await HTTP.POST('Usuario/Autenticar',body,{})
    },
    Listar: async () => {
        return await HTTP.POST('Usuario/ListarUsuarios','',{})
    },
    ListarHojasDocumento: async () => {
        return await HTTP.POST('Usuario/ListarHojasDocumento','',{})
    },
    CrearActualizar: async (body : any) => {
        return await HTTP.POST('Usuario/CrearActualizar',body,{})
    }
}