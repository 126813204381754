import { HTTP } from "../Http/Http"

export const DocumentoRepository = {
    CargarEmpresas: async (body : any) => {
        return await HTTP.POST('Documento/CargarEmpresas',body,{})
    },
    CargarReglaEmpresa: async (body : any) => {
        return await HTTP.POST('Documento/CargarReglaEmpresa',body,{})
    },
    SubirArchivo: async (body : any) => {
        return await HTTP.POST('Documento/SubirArchivo',body,{})
    },
    SubirArchivoCostos: async (body : any) => {
        return await HTTP.POST('Documento/SubirArchivoCostos',body,{})
    },
    ExportarExcel: async (body : any) => {
        return await HTTP.POST('Documento/ExportarExcel',body,{responseType: 'arraybuffer'})
    },
    CargarCostosPG: async (body : any) => {
        return await HTTP.POST('Documento/CargarCostosPG',body,{})
    }
}