import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import Loading from '../Components/Loading'
import { UsuarioService } from '../../Domain/Services/Usuario.service'

import '../Assets/Css/Login.css'

interface Autenticacion{
    grant_type: string,
    code: string,
    redirect_uri: string,
    Credencial:{
        Usuario: string,
        Contraseña: string,
    },
    refresh_token: string,
}

interface IProps {
}

interface IState {
    Autenticacion: Autenticacion,
    IsLoading: boolean
}

export default class Login extends React.Component<IProps, IState> {

    constructor(props : any){
        super(props)
        this.state = {
            Autenticacion:{
                grant_type: '',
                code: '',
                redirect_uri: '',
                Credencial:{
                    Usuario: '',
                    Contraseña: '',
                },
                refresh_token: '',
            },
            IsLoading: false
        }
    }

    componentDidMount(){
        localStorage.removeItem('TOKEN-UN1FL3X')
    }

    HandleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            Autenticacion:{
                ...this.state.Autenticacion,
                Credencial:{
                    ...this.state.Autenticacion.Credencial,
                    [e.target.name]: e.target.value
                }
            }
        })
    }

    HandleSubmit = (e : React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.setState({
            IsLoading: true
        }, () => {this.Autenticar()})
    };

    Autenticar(){ 
        UsuarioService.Autenticar(this.state.Autenticacion)
        .then( (data : any) => {
            if(data.success){
                var info = JSON.parse(data.data);
                localStorage.setItem('TOKEN-UN1FL3X',info.access_token)
                window.location.assign('Documento')
            }else{
                alert(data.data)
            }
        })
        .catch( error => {
            alert(error)
        })
        .finally( () => {
            this.setState({
                IsLoading: false
            })
        })
    }

    render(){
        return(
            <>
                {this.state.IsLoading ? 
                    <Loading Load={this.state.IsLoading}/>
                    :
                    <div>
                        <header className="toolbarLogin">
                            <nav className="toolbar_navigation">
                                <div className="image">
                                    <div className="imageAuth"></div>
                                </div>
                            </nav>
                        </header>
                            
                        <div className="authBox">
                            <div className="box">
                                <div className="subtitleAuth">Iniciar sesión en</div>
                                <div className="titleAuth">Touchless Payments</div>
                                    <form autoComplete="off" onSubmit={this.HandleSubmit}>
                                        <div className="p-col-fixed p-mt-4">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12 p-mt-3">
                                                    <InputText 
                                                        value={this.state.Autenticacion.Credencial.Usuario}
                                                        name="Usuario"
                                                        onChange={this.HandleChange}
                                                        type="text" 
                                                        placeholder="Usuario"/>
                                                </div>
                                            </div>
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12 p-mt-3">
                                                    <InputText 
                                                        value={this.state.Autenticacion.Credencial.Contraseña}
                                                        name="Contraseña"
                                                        onChange={this.HandleChange}
                                                        type="password"
                                                        placeholder="Contraseña"/>
                                                </div>
                                            </div>
                                            <div className="p-mt-1">
                                                <Button 
                                                    label="Ingresar"
                                                    style={{
                                                        background:'#0053af',
                                                        border:'#0053af',
                                                        width:'100%'
                                                    }}
                                                    className="p-ml-auto"/>
                                            </div>
                                        </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}
