import React from 'react'
import jwt from 'jwt-decode'
import ReglasDataTable from '../Components/ReglasDataTable'
import Loading from '../Components/Loading'
import Paginator from '../Components/Paginator'
import ModalFecha from '../Components/ModalFecha'
import ModalEstado from '../Components/ModalEstado'
import { TabView, TabPanel } from 'primereact/tabview'
import { DocumentoService } from '../../Domain/Services/Documento.service'
import CostosPG from '../Components/CostosPG'

import '../Assets/Css/DTDocumento.css'

interface IReporte{
    FechaInicio: Date
    FechaFin: Date
    IdUsuario: number
}

interface IPaginado{
    NumeroPagina : number
    RegistrosPagina : number
    NumeroPaginas : number
    fil_CodigoPlan : string
    fil_IDPlan : string
    fil_Categoria : string
    fil_Marca : string
    fil_TipoPlan : string
    fil_EANBonificado : string
    fil_EstadoRegla: number
    fil_Mes: string
    fil_Anio: number
    fil_EAN: string
    fil_Costo: number
}

interface ISniper{
    code: number,
    name: string
}

interface IRegistrosPagina{
    RegistrosPagina: number
}

interface IProps {
}

interface IState {
    Loading: boolean
    IsLoading: boolean
    HEmpresa: {PKID: number, Nombre: string}
    Paginado: IPaginado
    Documento: Array<object>
    Empresa: Array<ISniper>
    ActiveIndex: number
    SelectedEstado: any
    RegistrosPagina: IRegistrosPagina
    ModalIsOpenFechas: boolean
    Reporte: IReporte
    ModalIsOpen: boolean
    Regla: Array<object>
    Costos: Array<object>
}

export default class Documento extends React.Component<IProps, IState> {

    cPaginado = {
        NumeroPagina : 1,
        RegistrosPagina : 50,
        NumeroPaginas : 0,
        fil_CodigoPlan : '',
        fil_IDPlan : '',
        fil_Categoria : '',
        fil_Marca : '',
        fil_TipoPlan : '',
        fil_EANBonificado : '',
        fil_EstadoRegla: -2,
        fil_Mes: '',
        fil_Anio: 0,
        fil_EAN: '',
        fil_Costo: 0
    }

    cReporte = {
        FechaInicio: new Date(),
        FechaFin: new Date(),
        IdUsuario: 0
    }

    constructor(props : any){
        super(props)
        this.state = {
            Loading: false,
            IsLoading: false,
            HEmpresa: { PKID: 0, Nombre: ''},
            Paginado: this.cPaginado,
            Documento: [],
            Empresa: [],
            ActiveIndex: 0,
            SelectedEstado: null,
            RegistrosPagina: { RegistrosPagina : 50},
            ModalIsOpenFechas: false,
            Reporte: this.cReporte,
            ModalIsOpen: false,
            Regla: [],
            Costos: []
        }
    }

    componentDidMount(){
        this.CargarEmpresas()      
    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
     }
    
    CargarEmpresas(){
        var Token : string | null = localStorage.getItem('TOKEN-UN1FL3X')
        if(Token){
            var Decoded : any = jwt(Token)
            var Usuario = {
                PKID : Decoded.sub
            }
            this.setState({IsLoading: true})
            DocumentoService.CargarEmpresas(Usuario)
            .then( (data : any) => {
                if(data.success){
                    var info = JSON.parse(data.data);
                    var aEmpresa : any = []
                    Object.keys(info).forEach(function(key){
                        var iEmpresa = {
                            code: info[key].PKID,
                            name: info[key].Nombre,
                        }
                        aEmpresa.push(iEmpresa);
                    });
                    var cEmpresa = {
                        PKID : aEmpresa[0].code,
                        Nombre: aEmpresa[0].name
                    }
                    this.setState({
                        Empresa: aEmpresa,
                        HEmpresa: cEmpresa,
                    }, () => {
                        this.CargarHojaDocumento()
                    })
                }else{
                    alert(data.data)
                }
            })
            .finally( () => {
                this.setState({IsLoading: false})
            })
        }
    }

    CargarHojaDocumento(){
        var lElemento = {
            Empresa : this.state.HEmpresa,
            Paginado : this.state.Paginado
        }
        this.setState({Loading: true})
        {lElemento.Empresa.Nombre == 'LP'?
        DocumentoService.CargarCostosPG(lElemento)
        .then( (data : any) => {
            if(data.success){
                this.setState({
                    Documento: JSON.parse(data.data),
                    Paginado: JSON.parse(data.data)[0].Paginado
                })
            }
        })
        .finally( () => {
            this.setState({Loading: false})
        })
        :
        DocumentoService.CargarReglaEmpresa(lElemento)
        .then( (data : any) => {
            if(data.success){
                this.setState({
                    Documento: JSON.parse(data.data),
                    Paginado: JSON.parse(data.data)[0].Paginado
                })
            }
        })
        .finally( () => {
            this.setState({Loading: false})
        })
    }
        
    }
    
    HandleFileUpload = (Event : any, RefFile : any, Origen : any)=> {
        var Token : string | null= localStorage.getItem('TOKEN-UN1FL3X')
       if (Origen == 'Costos'){
        if(Token){
            var Decoded : any = jwt(Token)
            const FData = new FormData()
            FData.append("Files",Event.files[0]);
            FData.append("Usuario",Decoded.sub);
            this.setState({
                    Loading: true
            },() => {
                DocumentoService.SubirArchivoCostos(Event,FData)
                .then( (data : any) => {
                    alert(JSON.parse(data.data).Descripcion)
                    if(data.success) window.location.reload()
                })
                .catch((error) => {
                    alert(error)
                })
                .finally( () => {
                    this.setState({
                        Loading: false
                    })
                })
            })
        }
        RefFile.current.files = ''
        RefFile.current.state.files = ''
    }else{
        if(Token){
            var Decoded : any = jwt(Token)
            const FData = new FormData()
            FData.append("Files",Event.files[0]);
            FData.append("Usuario",Decoded.sub);
            this.setState({
                    Loading: true
            },() => {
                DocumentoService.SubirArchivo(Event,FData)
                .then( (data : any) => {
                    alert(JSON.parse(data.data).Descripcion)
                    if(data.success) window.location.reload()
                })
                .catch((error) => {
                    alert(error)
                })
                .finally( () => {
                    this.setState({
                        Loading: false
                    })
                })
            })
        }
        RefFile.current.files = ''
        RefFile.current.state.files = ''
    }
    }

   

    ChangeTab = (Event: {index : number}) => {                        
        this.setState({
            ActiveIndex: Event.index,
            HEmpresa: {
                PKID : this.state.Empresa[Event.index].code,
                Nombre: this.state.Empresa[Event.index].name
            },
            Paginado: this.cPaginado,
            RegistrosPagina: { 
                RegistrosPagina : 50
            }
        },() => this.CargarHojaDocumento())
    }

    HandlePreviousLL = () =>{
        this.setState({
            Paginado:{
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosPagina: 50,
                NumeroPaginas: 0
            }
        }, () => this.CargarHojaDocumento())
    }

    HandlePrevious = () =>{
        this.setState({
            Paginado:{
                ...this.state.Paginado,
                NumeroPagina: this.state.Paginado.NumeroPagina - 1
            }
        }, () => this.CargarHojaDocumento())
    }

    HandleFollowing = () =>{
        this.setState({
            Paginado:{
                ...this.state.Paginado,
                NumeroPagina: this.state.Paginado.NumeroPagina + 1
            }
        }, () => this.CargarHojaDocumento())
    }

    HandleFollowingLL = () =>{
        this.setState({
            Paginado:{
                ...this.state.Paginado,
                NumeroPagina: this.state.Paginado.NumeroPaginas
            }
        }, () => this.CargarHojaDocumento())
    }    

    OnChangeEstado = (Event : any) => {
        var cPaginado;
        if(Event.value === 'Sincronizado'){
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                fil_EstadoRegla : 0
            }
        } else if(Event.value === 'Ocurrió un error'){
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                fil_EstadoRegla : 1
            }
        } else if(Event.value === 'No Sincronizado'){
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                fil_EstadoRegla : -1
            }
        } else {
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                fil_EstadoRegla : -2
            }
        }
        this.setState({
            Paginado: cPaginado,
            SelectedEstado: Event.value
        }, () => this.CargarHojaDocumento())
    }

    Filter = (Event : any) => {
        
        if (Event.target.name == 'fil_Anio') {
            if (Event.target.value === '') {
                Event.target.value=0
             }
        }

        if (Event.target.name == 'fil_Costo') {
            if (Event.target.value === '') {
                Event.target.value=0
             }
        }

        this.setState({            
            Paginado:{
                ...this.state.Paginado,
                NumeroPagina : 1,
                [Event.target.name] : Event.target.value
            }
        })
    }

    FilterKeyDown = (Event : any) => {
        if (Event.key === 'Enter') {
            this.CargarHojaDocumento()
        }
    }

    OnRegistroChange = (Event : any) =>{
        this.setState({
            Paginado:{
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosPagina: Event.value.RegistrosPagina,
            },
            RegistrosPagina: Event.value
        }, () => this.CargarHojaDocumento())
    }

    OnChangeReporte = (Event : any) => {
        this.setState({
            Reporte:{
                ...this.state.Reporte,
                [Event.target.name]: Event.target.value
            }
        })
    }

    HandleSelectRow = (Regla : any) => {   
        this.setState({
            Regla: Regla.EstadoRegla
        },() => {
            this.setState({
                ModalIsOpen: true
            })
        })
    };

    DescargaReporte = (Event : any) =>{
        var Token : string | null= localStorage.getItem('TOKEN-UN1FL3X')
        if(Token){
            var Decoded : any = jwt(Token)
            this.setState({
                IsLoading: true,
                ModalIsOpenFechas: false,
                Reporte:{
                    ...this.state.Reporte,
                    IdUsuario: Decoded.sub
                }
            },() => {
                DocumentoService.ExportarExcel(this.state.Reporte)
                .then((data : any) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Reporte de liquidaciones.xlsx');
                        document.body.appendChild(link);
                        link.click();
                })
                .catch((error) => {
                    alert(error)
                })             
                .finally(() => 
                    this.setState({IsLoading : false})
                )
            })
        }
    }

    render(){
        return (
            <>
                {this.state.IsLoading ? 
                    <Loading Load={this.state.IsLoading}/>
                    :
                    <div style={{padding:'15px 15px 0 15px'}}>
                        <TabView                           
                        style={{ whiteSpace: 'pre-wrap', overflow: 'break-word', width:'180%' }}                     
                            activeIndex={this.state.ActiveIndex} 
                            onTabChange={(Event) => this.ChangeTab(Event)}>
                            {
                                this.state.Empresa.map((lEmpresa:any,index:number) =>
                                    <TabPanel header={lEmpresa.name} key={index}>
                                        {lEmpresa.name == 'LP' ? 

                                            <CostosPG
                                            HandleFileUpload = {this.HandleFileUpload}
                                            Costos = {this.state.Documento}
                                            SelectedEstado = {null}                                           
                                            Loading = {this.state.Loading}
                                            HandleSelectRow = {null}
                                            Paginado = {this.state.Paginado}
                                            Filter = {this.Filter}
                                            FilterKeyDown = {this.FilterKeyDown}
                                            HideModalFecha = {() => this.setState({ModalIsOpenFechas: true})}/>

                                            :

                                            <ReglasDataTable
                                            HandleFileUpload = {this.HandleFileUpload}
                                            Regla = {this.state.Documento}
                                            SelectedEstado = {this.state.SelectedEstado}
                                            OnChangeEstado = {this.OnChangeEstado}
                                            Loading = {this.state.Loading}
                                            HandleSelectRow = {this.HandleSelectRow}
                                            Paginado = {this.state.Paginado}
                                            Filter = {this.Filter}
                                            FilterKeyDown = {this.FilterKeyDown}
                                            HideModalFecha = {() => this.setState({ModalIsOpenFechas: true})}/>
                                        }

                                        {lEmpresa.name == 'LP' ? 

                                            <div style={{width:'50%'}}>
                                            <Paginator                                             
                                            Paginado= {this.state.Paginado}
                                            HandlePreviousLL= {this.HandlePreviousLL}
                                            HandlePrevious= {this.HandlePrevious}
                                            HandleFollowing= {this.HandleFollowing}
                                            HandleFollowingLL= {this.HandleFollowingLL}
                                            RegistrosPagina= {this.state.RegistrosPagina}
                                            OnRegistroChange= {this.OnRegistroChange}/>
                                            </div>

                                        :

                                            <Paginator
                                            Paginado= {this.state.Paginado}
                                            HandlePreviousLL= {this.HandlePreviousLL}
                                            HandlePrevious= {this.HandlePrevious}
                                            HandleFollowing= {this.HandleFollowing}
                                            HandleFollowingLL= {this.HandleFollowingLL}
                                            RegistrosPagina= {this.state.RegistrosPagina}
                                            OnRegistroChange= {this.OnRegistroChange}/>    

                                        }

                                        {lEmpresa.name == 'LP' ? 

                                            <div/>

                                            :
                                            
                                            <ModalEstado
                                            Modal = {this.state.ModalIsOpen}
                                            EstadoRegla = {this.state.Regla} 
                                            HideModal = {() => this.setState({ModalIsOpen: false})}/>

                                        }
                                    
                                        <form onSubmit={this.DescargaReporte}>
                                            <ModalFecha
                                                Modal = {this.state.ModalIsOpenFechas}
                                                HideModal = {() => this.setState({ModalIsOpenFechas : false})}
                                                OnChange = {this.OnChangeReporte}
                                                Reporte = {this.state.Reporte}/>
                                        </form>
                                    </TabPanel>
                                )
                            }
                        </TabView>
                    </div>
                }
            </>
        )
    }
}
