import React, { RefObject } from 'react'
import jwt from 'jwt-decode'
import { FileUpload } from 'primereact/fileupload'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'

import '../Assets/Css/DataTable.css'

interface IProps {
    HandleFileUpload: any,
    Regla: Array<object>,
    SelectedEstado: any,
    OnChangeEstado: any,
    Loading: boolean,
    HandleSelectRow: any,
    Paginado : {
        NumeroPagina : number,
        RegistrosPagina : number,
        NumeroPaginas : number,
        fil_CodigoPlan: string,
        fil_IDPlan: string,
        fil_Categoria: string,
        fil_Marca: string,
        fil_TipoPlan: string,
        fil_EANBonificado: string,
        fil_EstadoRegla: number},
    Filter: any,
    FilterKeyDown: any
    HideModalFecha: any
}

interface IState {
    RefFile: RefObject<FileUpload>,
    Estados: Array<string>,
    ExpandedRows: Array<object>
}

export default class ReglasDataTable extends React.Component<IProps, IState> {

    constructor(props : any){
        super(props)
        this.state = {
            RefFile: React.createRef<FileUpload>(),
            Estados: [
                'Sincronizado', 
                'No Sincronizado', 
                'Ocurrió un error'
            ],
            ExpandedRows: []
        }
    }

    Header(){
        var Token : string | null = localStorage.getItem('TOKEN-UN1FL3X')
        if(Token){
            var Decoded : any = jwt(Token)
            var Origen =  "Reglas"
            return(
                <div className="p-d-flex p-jc-between">
                    {Decoded.IsDocumento === 'True' ? 
                        <FileUpload 
                            ref={this.state.RefFile}
                            mode="basic" 
                            name="file" 
                            auto 
                            uploadHandler={(Event) => this.props.HandleFileUpload(Event, this.state.RefFile, Origen)}
                            customUpload
                            accept=".xlsm,application/msexcel,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            chooseLabel="Subir documento"/>
                        :
                        <></>
                    }
                    <Button 
                        label="Descargar reporte de liquidaciones" 
                        onClick={this.props.HideModalFecha} 
                        autoFocus/>
                </div>
            )
        }
    }

    TemplateFilterEstado(option : any){
        if(option === 'Sincronizado')
            return <span style={{color:'#66BB6A', fontWeight:700}}>Sincronizado</span>
        else if(option === 'No Sincronizado')
            return <span style={{color:'#FCB500', fontWeight:700}}>No Sincronizado</span>
        else
            return <span style={{color:'#FF5252', fontWeight:700}}>Ocurrió un error</span>
    }

    FilterEst(){
        return(
            <Dropdown 
                value= {this.props.SelectedEstado} 
                options= {this.state.Estados} 
                onChange= {(Event) => this.props.OnChangeEstado(Event)}
                itemTemplate= {this.TemplateFilterEstado}
                placeholder= "Select" 
                showClear 
                className= "p-column-filter" />
        )
    }

    FilterCP(){
        return(
            <InputText  
                className="p-column-filter"
                name="fil_CodigoPlan"
                value={this.props.Paginado.fil_CodigoPlan} 
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}/>
        )
    }

    FilterIDP(){
        return(
            <InputText  
                className="p-column-filter"
                name="fil_IDPlan"
                value={this.props.Paginado.fil_IDPlan} 
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}/>
        )
    }
    
    FilterCat(){
        return(
            <InputText
                className="p-column-filter"
                name="fil_Categoria"
                value={this.props.Paginado.fil_Categoria} 
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}/>
        )
    }
    
    FilterMar(){
        return(
            <InputText
                className="p-column-filter"
                name="fil_Marca"
                value={this.props.Paginado.fil_Marca} 
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}/>
        )
    }
    
    FilterTP(){
        return(
            <InputText
                className="p-column-filter"
                name="fil_TipoPlan"
                value={this.props.Paginado.fil_TipoPlan} 
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}/>
        )
    }
    
    FilterEAN(){
        return(
            <InputText
                className="p-column-filter"
                name="fil_EANBonificado"
                value={this.props.Paginado.fil_EANBonificado} 
                onChange={(e) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}/>
        )
    }

    RowExpansionTemplate = (Data : any) =>{
        return (
            <div className="orders-subtable">
                <DataTable value={Data.items}>
                    <Column field="EAN" header="EAN"/>
                    <Column field="Producto" header="Producto"/>
                    <Column field="Cantidad" header="Cantidad"/>
                    <Column field="UnidadMedida" header="UnidadMedida"/>
                </DataTable>
            </div>
        );
    }

    BodyIncluirCaracteristica = (RowData : any) => {
        return RowData.IncluirCaracteristica;        
}

BodyIncluirCodigoCaracteristica = (RowData : any) => {
    return RowData.IncluirCodigoCaracteristica;        
}

BodyExcluirCaracteristica = (RowData : any) => {
    return RowData.ExcluirCaracteristica;        
}

BodyExcluirCodigoCaracteristica = (RowData : any) => {
    return RowData.ExcluirCodigoCaracteristica;        
}

BodySucursales = (RowData : any) => {
    return RowData.Sucursales;        
}

BodyEditable = (RowData : any) => {                
    if(RowData.Editable) return <span>1</span>;
    else return <span>0</span>;   
}



    BodyIncluido = (RowData : any) => {
                return RowData.Incluido;        
    }

    BodyExcluido = (RowData : any) => {
        return RowData.Excluido;
       
    }

    BodyBodega = (RowData : any) => {
        if(RowData.Bodegas) return <span>Si</span>;
        else return <span>No</span>;
    }
    
    BodyMercados = (RowData : any) => {
        if(RowData.Mercados) return <span>Si</span>;
        else return <span>No</span>;
    }
    
    BodyMayoristas = (RowData : any) => {
        if(RowData.Mayoristas) return <span>Si</span>;
        else return <span>No</span>;
    }

    BodyEstado = (RowData : any) => {
        return (
            <React.Fragment>
                {   
                    RowData.EstadoRegla ?

                    RowData.EstadoRegla.Codigo === '-1' ?
                        <span style={{color:'#FCB500', fontWeight: 700}}>No Sincronizado</span>
                        :
                        (
                            RowData.EstadoRegla.Codigo === '0' ?
                                <span style={{color:'#66BB6A', fontWeight: 700}}>{RowData.EstadoRegla.Mensaje}</span>
                                :
                                <span style={{color:'#FF5252', fontWeight: 700}}>Ocurrió un error</span>
                        )

                        :

                        <span style={{color:'#FF5252', fontWeight: 700}}>Ocurrió un error</span>
                }
            </React.Fragment>
        )
    }       
    
    render(){
        return(
            <div className="DataTable">
                <DataTable                     
                    style={{ whiteSpace: 'pre-wrap', overflow: 'break-word', width:'100%' }}
                    value={this.props.Regla}
                    selectionMode="single"
                    header={this.Header()}
                    expandedRows={this.state.ExpandedRows}
                    onRowToggle={(Event : any) => this.setState({ExpandedRows : Event.data})}
                    rowExpansionTemplate={this.RowExpansionTemplate}
                    className="p-datatable-customers"
                    loading={this.props.Loading}    
                    onSelectionChange={Event => this.props.HandleSelectRow(Event.value)} 
                    emptyMessage="No se encuentra ninguna regla registrada.">
                        <Column expander style={{ width: '3em' }} />
                        <Column field="CodigoPlan" header="CodigoPlan" filter filterElement={this.FilterCP()} style={{ width: '10%',textAlign:'center' }}/>
                        <Column field="IDPlan" header="IDPlan" filter filterElement={this.FilterIDP()} style={{ width: '10%',textAlign:'center' }}/>
                        <Column field="Categoria" header="Categoria" filter filterElement={this.FilterCat()} style={{ width: '10%',textAlign:'center' }}/>
                        <Column field="Marca" header="Marca" filter filterElement={this.FilterMar()} style={{ width: '10%',textAlign:'center' }}/>
                        <Column header="B." body={this.BodyBodega} style={{ width: '5%',textAlign:'center' }}/>
                        <Column header="Me." body={this.BodyMercados} style={{ width: '5%',textAlign:'center' }}/>
                        <Column header="Ma." body={this.BodyMayoristas} style={{ width: '5%',textAlign:'center' }}/>
                        <Column field="TipoPlan" header="TipoPlan" filter filterElement={this.FilterTP()} style={{ width: '10%',textAlign:'center'}}/>
                        <Column field="EANBonificado" header="EANBonificado" filter filterElement={this.FilterEAN()} style={{ width: '12%',textAlign:'center' }}/>
                        <Column field="EstadoRegla.Codigo" header="EstadoRegla" body={this.BodyEstado} filter filterElement={this.FilterEst()} style={{ width: '12%',textAlign:'center' }}/>
                        <Column field="Incluido" header="Vendedores Incluidos" body={this.BodyIncluido} style={{ width: '15%',textAlign:'center' }}/>
                        <Column field="Excluido" header="Vendedores Excluidos" body={this.BodyExcluido} style={{ width: '15%',textAlign:'center' }}/>
                        <Column field="IncluirCaracteristica" header="Incluir Carac- terística" body={this.BodyIncluirCaracteristica} style={{ width: '10%',textAlign:'center' }}/>
                        <Column field="IncluirCodigoCaracteristica" header="Incluir Código Característica" body={this.BodyIncluirCodigoCaracteristica} style={{ width: '15%',textAlign:'center' }}/>
                        <Column field="ExcluirCaracteristica" header="Excluir Carac- terística" body={this.BodyExcluirCaracteristica} style={{ width: '10%',textAlign:'center' }}/>
                        <Column field="ExcluirCodigoCaracteristica" header="Excluir Código Característica" body={this.BodyExcluirCodigoCaracteristica} style={{ width: '15%',textAlign:'center' }}/>                        
                        <Column field="Sucursales" header="Sucursales" body={this.BodySucursales} style={{ width: '10%',textAlign:'center' }}/>
                        <Column field="Editable" header="Editable" body={this.BodyEditable} style={{ width: '10%',textAlign:'center' }}/>
                </DataTable>
            </div>            
        )
    }
}