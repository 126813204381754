import { DocumentoRepository } from '../../Infrastructure/Repositories/Documento.repository'

const SubirArchivo = (Event : any,FData : any) => {
    if(
        Event.files[0].type !== 'application/vnd.ms-excel.sheet.macroEnabled.12' && 
        Event.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        Event.files[0].type !== 'application/vnd.ms-excel'
    ){
        throw new Error('No puede subir un documento con diferente formato excel')
    }else{
        return DocumentoRepository.SubirArchivo(FData)
    }
}

const SubirArchivoCostos = (Event : any,FData : any) => {
    if(
        Event.files[0].type !== 'application/vnd.ms-excel.sheet.macroEnabled.12' && 
        Event.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        Event.files[0].type !== 'application/vnd.ms-excel'
    ){
        throw new Error('No puede subir un documento con diferente formato excel')
    }else{
        return DocumentoRepository.SubirArchivoCostos(FData)
    }
}

const ExportarExcel = (Reporte : any) => {
    if(Reporte.FechaInicio <= Reporte.FechaFin){
        return DocumentoRepository.ExportarExcel(Reporte)
    }else{
        throw new Error("La fecha de inicio debe ser mayor que la fecha fin.")
    }
}

export const DocumentoService = {
    CargarEmpresas: (body : any) => {
        return DocumentoRepository.CargarEmpresas(body)
    },
    CargarReglaEmpresa: (body : any) => {
        return DocumentoRepository.CargarReglaEmpresa(body)
    },
    CargarCostosPG: (body : any) => {
        return DocumentoRepository.CargarCostosPG(body)
    },
    SubirArchivo,
    SubirArchivoCostos,
    ExportarExcel
}