import React from 'react'
import jwt from 'jwt-decode'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import NavigationBar from './Infrastructure/Components/NavigationBar'
import Login from "./Infrastructure/Pages/Login"
import Documento from "./Infrastructure/Pages/Documento"
import Administracion from './Infrastructure/Pages/Administracion'

interface IToken{
    sub: number
    Nombre: string
    Apellido: string
    IsDocumento: string
    IsAdministrador: string
    exp: number
    nbf: number
}

interface IProps {
}

interface IState {
    Token: IToken,
}


export default class App extends React.Component<IProps, IState> {       

    constructor(props : any){
        super(props)
        this.state = {
            Token : {
                sub: 0,
                Nombre: '',
                Apellido: '',
                IsDocumento: '',
                IsAdministrador: '',
                exp: 0,
                nbf: 0
            }
        }
    }

    componentDidMount(){
        var Token : string | null= localStorage.getItem('TOKEN-UN1FL3X')
        if(Token){
            this.setState({
                Token: jwt(Token)
            })
        }
    }

    render(){
        const PrivateRoute = ({component: Component, authed, ...rest} : any) => {
            return (
                <Route
                    {...rest}
                    render={(props : any) => (authed === true ? 
                    <>
                        <NavigationBar Token={this.state.Token}/>
                        {
                            this.state.Token.IsAdministrador === 'True'? 
                            <>
                                <Route 
                                    path="/Documento" 
                                    component={Documento}/>
                                <Route 
                                    path="/Administracion" 
                                    component={Administracion}/>
                            </>
                            :
                            <>
                                <Route path="/Documento" component={Documento}/>
                            </>
                        }
                    </>
                :
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />)} 
                />
            )
        }

        return(
            <BrowserRouter  basename="">
                <Switch>
                <Route exact path="/" component={Login}></Route>
                <PrivateRoute 
                    authed={localStorage.getItem('TOKEN-UN1FL3X') ? true : false} 
                    component={Documento}/>
                </Switch>
            </BrowserRouter>
        )
    }
}
