import { UsuarioRepository } from '../../Infrastructure/Repositories/Usuario.repository'
import { Credencial } from '../Models/CredencialDTO'
import { Usuario } from '../Models/UsuarioDTO'

const ValidarSchema = (Value: Usuario, Mode: string) => {
    const Errores : {Nombres: string, Usuario: string, Contraseña: string} = {
        Nombres: !Value.Users.Nombres ? 'Empresa es requerido' : '',
        Usuario: !Value.Usuario ? 'Usuario es requerido' : '',
        Contraseña: Mode === 'Create' ? (!Value.Contraseña ? 'Contraseña es requerido':'') : ''
    }
    return Errores
}

const ExpresionRegular = (Contrasena : string) => {
    var Band = false
    var regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/)
    if(regex.test(Contrasena)) Band = !Band
    return Band
}

const Autenticar = (body : Credencial) => {
    return UsuarioRepository.Auntenticar(body)
}

const Listar = () => {
    return UsuarioRepository.Listar()
}

const ListarHojasDocumento = () => {
    return UsuarioRepository.ListarHojasDocumento()
}

const CrearActualizar = (body: Usuario) => {
    return UsuarioRepository.CrearActualizar(body)
}

export const UsuarioService = {
    ValidarSchema,
    ExpresionRegular,
    Autenticar,
    Listar,
    ListarHojasDocumento,
    CrearActualizar
}