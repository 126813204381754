import React, { RefObject } from 'react'
import jwt from 'jwt-decode'
import { FileUpload } from 'primereact/fileupload'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'

import '../Assets/Css/DataTable.css'

interface IProps {
    HandleFileUpload: any,
    Costos: Array<object>,
    SelectedEstado: any,   
    Loading: boolean,
    HandleSelectRow: any,
    Paginado : {
        NumeroPagina : number,
        RegistrosPagina : number,
        NumeroPaginas : number,
        fil_Mes: string,
        fil_Anio: number,
        fil_EAN: string,
        fil_Costo: number
    },
    Filter: any,
    FilterKeyDown: any
    HideModalFecha: any
}

interface IState {
    RefFile: RefObject<FileUpload>    
}

export default class CostosPG extends React.Component<IProps, IState> {

    constructor(props : any){
        super(props)
        this.state = {
            RefFile: React.createRef<FileUpload>()          
            
        }
    }

    

    Header(){
        var Token : string | null = localStorage.getItem('TOKEN-UN1FL3X')
        if(Token){
            var Decoded : any = jwt(Token)
            var Origen =  "Costos"
            return(
                <div className="p-d-flex p-jc-between">
                    {Decoded.IsDocumento === 'True' ? 
                        <FileUpload 
                            ref={this.state.RefFile}
                            mode="basic" 
                            name="file" 
                            auto 
                            uploadHandler={(Event) => this.props.HandleFileUpload(Event, this.state.RefFile,Origen)}
                            customUpload
                            accept=".xlsm,application/msexcel,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            chooseLabel="Importar Costos"/>
                        :
                        <></>
                    }
                    
                </div>
            )
        }
    }

   
    FilterMes(){
        return(
            <InputText  
                className="p-column-filter"
                name="fil_Mes"
                value={this.props.Paginado.fil_Mes} 
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}/>
        )
    }

    FilterAnio(){
        return(
            <InputText  
                type='number'
                min="0"
                step="1"
                pattern="^[0-9]"      
                required          
                className="p-column-filter"
                name="fil_Anio"                
                value={this.props.Paginado.fil_Anio}                 
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}/>
        )
    }
    
    FilterEAN(){
        return(
            <InputText
                className="p-column-filter"
                name="fil_EAN"
                value={this.props.Paginado.fil_EAN} 
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}/>
        )
    }
    
    FilterCosto(){
        return(
            <InputText
                type='number'
                className="p-column-filter"
                name="fil_Costo"
                value={this.props.Paginado.fil_Costo} 
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}/>
        )
    }
    
    

    BodyMes = (RowData : any) => {
        const mesNombre = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "octubre", "noviembre", "diciembre"];
        return mesNombre[RowData.Mes-1];
    }

    
    render(){
        return(
            <div className="DataTable">
                <DataTable 
                style={{ whiteSpace: 'pre-wrap', overflow: 'break-word', width:'50%' }}
                    value={this.props.Costos}
                    selectionMode="single"
                    header={this.Header()}   
                    className="p-datatable-customers"
                    loading={this.props.Loading}                    
                    emptyMessage="No se encuentra ningún costo registrado.">                        
                        <Column field="Mes" body={this.BodyMes} header="Mes" filter filterElement={this.FilterMes()} style={{ width: '25%',textAlign:'center' }}/>
                        <Column field="Anio" header="Año" filter filterElement={this.FilterAnio()} style={{ width: '25%',textAlign:'center' }}/>
                        <Column field="EAN" header="EAN" filter filterElement={this.FilterEAN()} style={{ width: '25%',textAlign:'center' }}/>
                        <Column field="Costo" header="Costo" filter filterElement={this.FilterCosto()} style={{ width: '25%',textAlign:'center' }}/>                       
                </DataTable>
            </div>
        )
    }
}